import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"

const CalendarContainer = styled.section`
  margin: 1rem;
  height: calc(100vh - 56px - 55px - 2rem);
`

export default function Termine({ data, location }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Termine"
      description="Termine des Gau Alt-Burgunds"
      location={location}
    >
      <CalendarContainer>
        <iframe
          sandbox={"allow-same-origin allow-scripts"}
          src="https://cloud.altburgund.de/apps/calendar/embed/GCPG2ToAJKG4Mezc"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          title={"nextcloud_calendar"}
          style={{
            maxWidth: "100%",
            minHeight: "600px",
            height: "100%",
          }}
        />
      </CalendarContainer>
    </Layout>
  )
}
